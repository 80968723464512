import "./vendors/modernizr"
window.cachedPages = [];
window.ajaxed = false;
window.loaderDebug = true;
window.isLoadedPage = false;
window.imagesCanvasArray = [];
import  imagesLoaded from 'imagesloaded';
let pageWrapper = document.querySelector(".all-page-wrapper");
const preloaderDiv = document.querySelector(".page-preloader");
if (!ajaxed) {
    pageWrapper = document.querySelector(".all-page-wrapper");
    if (loaderDebug) console.log("Started from Page ,  Loaded Dom..");
    formatePage(pageWrapper)
}
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


let loaderNumber1 = preloaderDiv.querySelector(".number1");
let loaderNumber2 = preloaderDiv.querySelector(".number2");

function updateLoader(percentsLoaded) {

    let percentsText= String(percentsLoaded);
    let firstNumber = parseInt(percentsText.charAt(0));
    let secondNumber = parseInt(percentsText.charAt(1));
    if(percentsLoaded < 10){
        firstNumber = 0;
        secondNumber = parseInt(percentsText.charAt(0));
    }
    if(percentsLoaded >= 100){
        firstNumber = 9;
        secondNumber = 9;
    }
    loaderNumber1.style.transform = "translateY(" + (-firstNumber*10) + "%)";
    loaderNumber2.style.transform = "translateY(" + (-secondNumber*10) + "%)";
}

//Вначале определяем то, что будет загружаться, и потом если загрузилось - показываем страницу

function formatePage(pageData, modalData) {
    let imagesArray = [];
    let withVideo = false;
    let picturesLoaded = false;
    let videoLoaded = false;
    let percentageLoaded = 0;
    let currentImagesLoaded = 0;


    document.querySelectorAll(".jsPreload").forEach(preloadBox => {
        // work with picture RETINA

        preloadBox.querySelectorAll("picture").forEach(thisPicture => {
            var imageToLoad;
            var image_src;
            var image_srcset;

			//mobile devices
			if(Modernizr.device_xs){
				if(Modernizr.webp){
					thisPicture.querySelectorAll('source').forEach(thisSource => {
						//if supports webp
						if(thisSource.type === "image/webp" && thisSource.media === "(max-width: 768px)"){
							image_srcset = thisSource.srcset;
							image_src = image_srcset.split(',')[0];
						}
					});
				}

				if(!image_src){
					image_src = thisPicture.querySelector('img').src
				}

			}

			//desktop
			else{
				thisPicture.querySelectorAll('source').forEach(thisSource => {
					if(thisSource.media === "(min-width: 768px)"){
						if(Modernizr.webp){
							if(thisSource.type === "image/webp"){
								image_srcset = thisSource.srcset;
							}
						}
						else{
							if(thisSource.type != "image/webp"){
								image_srcset = thisSource.srcset;
							}
						}


					}

					//Если в picture нет source webp или srcset не найден, берем любой.
					if(!image_srcset){
						if(thisSource.media === "(min-width: 768px)"){
							image_srcset = thisSource.srcset;
						}
					}
				});
				if(Modernizr.retina){
					var lastsrcset = image_srcset.split(',');
					var lastsrc = lastsrcset[(lastsrcset.length - 1)];
					var lastpuresrc = lastsrc.split(' ');
					image_src = lastpuresrc[1];
				}
				else{
					image_src = image_srcset.split(',')[0];
				}

			}

            var img = new Image();
            img.src = image_src;
			//console.log(image_src)
            imageToLoad = img;
            imagesArray.push(imageToLoad)

        });


        preloadBox.querySelectorAll("img").forEach(thisImg => {
            if(!thisImg.closest("picture")){
                var image_src = thisImg.src;
                var imageToLoad;
                if(Modernizr.retina){
                    var imageSrcset = thisImg.srcset;
                    imageSrcset = imageSrcset.slice(0, -2).split(' ');
                    image_src = imageSrcset[0];
                }
                if(image_src){
                    var img = new Image();
                    img.src = image_src;
                    imageToLoad = img;
                    imagesArray.push(imageToLoad)
                }
            }

        });
        //если нужно сначала загрузить видео!
        preloadBox.querySelectorAll(".jsLoadVideo").forEach(videoElement => {
            withVideo = true;
            let videoSource = videoElement.querySelector("source")
            let videoDataSrc = videoSource.dataset.src;
            videoSource.setAttribute('src',videoDataSrc )
            videoElement.load();
            let CheckVideoDownLoadTimer = setInterval(alertFunc, 300);
            function alertFunc() {

                if(!videoLoaded){
                    videoElement.pause();
                }
                if(videoElement.readyState >= 4){
                    clearInterval(CheckVideoDownLoadTimer)
                    videoLoaded = true;
					videoElement.play();
                }
            }
        })

		preloadBox.querySelectorAll(".jsLoadVideoBoxFirst").forEach(videoElement => {
			var video = document.createElement('video');
			var sourceMP4 = document.createElement("source");
			let extension = ".mp4";
			sourceMP4.src = videoElement.dataset.src + extension;
			sourceMP4.type = "video/mp4";
			video.autoplay = true;
			video.loop = true;
			video.muted = true;
			video.playsInline = true;
			video.append(sourceMP4);
			videoElement.append(video);
			let CheckVideoDownLoadTimer = setInterval(alertFunc, 300);
			function alertFunc() {
				video.pause();
				if(video.readyState >= 4){
					clearInterval(CheckVideoDownLoadTimer)
					videoElement.setAttribute('data-loaded',true )
				}
			}
		})

		preloadBox.querySelectorAll(".jsLoadVideoBoxOld").forEach(videoElement => {
			var video = document.createElement('video');
			var sourceMP4 = document.createElement("source");
			let extension = ".mp4";
			sourceMP4.src = videoElement.dataset.src + extension;
			sourceMP4.type = "video/mp4";
			video.autoplay = true;
			video.loop = true;
			video.muted = true;
			video.playsInline = true;
			video.append(sourceMP4);
			videoElement.append(video);
			let CheckVideoDownLoadTimer = setInterval(alertFunc, 300);
			function alertFunc() {
				video.pause();
				if(video.readyState >= 4){
					clearInterval(CheckVideoDownLoadTimer)
					videoElement.setAttribute('data-loaded',true )
					console.log("videoLOADED")
				}
			}
		})

		//if we have this, we wait page until this video fully loaded (can play)
		document.querySelectorAll(".jsLoadVideoBoxFirst").forEach(videoElement => {
			withVideo = true;
			videoLoaded = false;
			let allVideoCheckDownloadTimer = setInterval(downLoadCheckerAll, 100);
			function downLoadCheckerAll() {
				if(videoElement.dataset.loaded){
					clearInterval(allVideoCheckDownloadTimer)
					videoLoaded = true;
					console.log("firstVideoLoaded")
				}
			}

		})





    });
    //video frames loader add
    document.querySelectorAll("#hero-lightpass").forEach(thisCanvas => {
        console.log("canvas video detected");
        const frameCount = thisCanvas.dataset.frames;
        const framesPath = thisCanvas.dataset.path;
        let supportedExtension = ".webp";
		console.log(Modernizr.webp)
        if(!Modernizr.webp){
            supportedExtension = ".jpg";
        }
        for (let i = 0; i <= frameCount; i++) {
            const img = new Image();
            img.src = framesPath + i.toString().padStart(4, '0')+supportedExtension;
            imagesCanvasArray.push(img); // для того, чтобы передать в другой файл скрипта
            imagesArray.push(img)
        }
    })

    var imgLoad = imagesLoaded(imagesArray);
    imgLoad.on( 'progress', function(instanse, image) {
        let result = image.isLoaded ? 'loaded' : 'broken';
        ++currentImagesLoaded;
        percentageLoaded = parseInt(100 / imagesArray.length * currentImagesLoaded);
        //console.log(percentageLoaded)
       // console.log( 'image is ' + result + ' for ' + image.img.src );
    });
    imgLoad.on( 'done', function() {
        document.querySelector(".jsPreload").classList.add('loadedPicture');
        console.log("images loaded")
        picturesLoaded = true;
    });

    let CheckLoadingProgress = setInterval(checkerLoadingProgress, 1000);
    function checkerLoadingProgress() {
        updateLoader(percentageLoaded);
    }

    let loadingTime = 0;
    let CheckLoadingTime = setInterval(checkerLoading, 300);
    function checkerLoading() {
        loadingTime += 0.3;

        if(loadingTime >= 0.6){
            preloaderDiv.classList.add('loader-long')

        }
        if(!withVideo){
			console.log("without video")
            if(picturesLoaded === true){
                clearInterval(CheckLoadingTime);
                clearInterval(CheckLoadingProgress);
                updateLoader(99);
                launch();
            }

        }
        else{
			console.log("with video... videoLoaded: "+ videoLoaded )

            if(videoLoaded  === true &&  picturesLoaded === true){
				console.log("videoLoaded true")
                clearInterval(CheckLoadingTime);
                clearInterval(CheckLoadingProgress);
                updateLoader(99);
                launch();
            }
        }

    }

}

function launch(){
    window.isLoadedPage = true;
    let url = window.location.href.replace(/^.*\/\/[^\/]+/, '');
    window.cachedPages.push(url)
    document.querySelector("body").classList.add('loaded-page');
}

if(window.innerWidth < 768){
    document.querySelectorAll(".js_xsNoChangeVH").forEach(element => {
        element.style.height = window.innerHeight+60 +"px";
    });
}
//631926 ms dev server





